import NiceSelect from "nice-select2";
import { tns } from 'tiny-slider/dist/tiny-slider';

const toggleFormVisibility = (showRims) => {
    document.getElementById('formRims').classList.toggle('hidden', !showRims);
    document.getElementById('formTire').classList.toggle('hidden', showRims);
    document.getElementById('rimsForm').classList.toggle('active', showRims);
    document.getElementById('tiresForm').classList.toggle('active', !showRims);
};

document.getElementById('rimsForm').addEventListener('click', () => toggleFormVisibility(true));
document.getElementById('tiresForm').addEventListener('click', () => toggleFormVisibility(false));

// Funkcja inicjująca NiceSelect
const initNiceSelect = (select) => {
    const options = {
        searchable: true, // Usunięcie warunku wykluczającego tireSeason
        placeholder: 'Wybierz',
        searchtext: 'Szukaj',
        selectedtext: ' wybrano'
    };
    new NiceSelect(select, options);
};

// Funkcja do załadowania danych z JSON do odpowiednich selectów
const loadTireData = async () => {
    try {
        const response = await fetch('https://opony.strefakierowcy.pl/tires.json');
        const data = await response.json();

        const selectElements = {
            tireWidth: document.getElementById('tireWidth'),
            tireProfile: document.getElementById('tireProfile'),
            rimDiameter: document.getElementById('rimDiameter'),
            tireSeason: document.getElementById('tireSeason'), // Dodajemy tireSeason
            rimSize: document.getElementById('rimSize'), // Dodajemy rimSize
            rimDiameters: document.getElementById('rimDiameters'), // Dodajemy rimDiameter
            rimScrew: document.getElementById('rimScrew'), // Dodajemy rimScrew
            rimSpacing: document.getElementById('rimSpacing'), // Dodajemy rimScrew
            // Dodaj inne selecty, jeśli są potrzebne
        };

        // Funkcja do aktualizacji opcji selecta
        const updateSelectOptions = (selectElement, options) => {
            selectElement.innerHTML = '<option value="">Wybierz</option>'; // Dodanie placeholdera
            options.forEach(option => {
                const optionElement = document.createElement('option');
                optionElement.value = option.id || option.value;
                optionElement.textContent = option.width || option.value; // Dopasowanie do struktury JSON
                selectElement.appendChild(optionElement);
            });
            initNiceSelect(selectElement); // Inicjalizacja NiceSelect po dodaniu opcji
        };

        // Aktualizacja każdego selecta na podstawie danych z JSON
        if (selectElements.tireWidth) {
            updateSelectOptions(selectElements.tireWidth, data.tireWidth);
        }
        if (selectElements.tireProfile) {
            updateSelectOptions(selectElements.tireProfile, data.tireProfile);
        }
        if (selectElements.rimDiameter) {
            updateSelectOptions(selectElements.rimDiameter, data.rimDiameter);
        }

        const initNiceSelectForElements = (selectElements) => {
            const elementsToCheck = [
                'tireSeason',
                'rimSize',
                'rimDiameters',
                'rimScrew',
                'rimSpacing'
            ];

            elementsToCheck.forEach(element => {
                if (selectElements[element]) {
                    initNiceSelect(selectElements[element]);
                }
            });
        };

        initNiceSelectForElements(selectElements);

    } catch (error) {
        console.error('Błąd podczas ładowania danych z JSON:', error);
    }
};

// Ładowanie danych po załadowaniu DOM
document.addEventListener('DOMContentLoaded', () => {
    loadTireData(); // Załaduj dane z JSON do wszystkich selectów
});


document.querySelectorAll('.products').forEach(section => {
    const carouselContainer = section.querySelector('.products__carousel');
    const navContainer = section.querySelector('.products__nav');

    tns({
        container: carouselContainer,
        slideBy: 1,
        autoplay: false,
        loop: true,
        mouseDrag: true,
        nav: false,
        controlsContainer: navContainer,
        items: 1.15,
        gutter: 8,
        center: false,
        responsive: {
            570: {
                items: 2.15,
                gutter: 16,
            },
            768: {
                items: 1.1,
                gutter: 24,
                edgePadding: 0,
            },
            1280: {
                gutter: 32,
                items: 2,
                edgePadding: 40,
                loop: true,
            }
        }
    });
});

document.addEventListener("DOMContentLoaded", () => {
    const removeWidow = (text) => {
        let words = text.split(' ');
        if (words.length <= 1) {
            return text;
        }
        let allButLastWord = words.slice(0, -1).join(' ');
        let lastWord = words[words.length - 1];
        return `${allButLastWord}\u00A0${lastWord}`;
    };

    document.querySelectorAll('.story__content p').forEach(element => {
        element.innerHTML = removeWidow(element.innerHTML);
    });
});

document.getElementById('story__content-button').addEventListener('click', (event) => {
    event.preventDefault();

    let content = document.querySelector('.story__content');
    let button = event.currentTarget;

    if (content.classList.contains('expanded')) {
        content.classList.remove('expanded');
        button.innerHTML = 'Rozwiń opis <span>+</span>';
    } else {
        content.classList.add('expanded');
        button.innerHTML = 'Zwiń opis <span>-</span>';
    }
});